import * as React from "react"
import { Link } from "gatsby"
import Navigation from "./navigation"
import styled from "@emotion/styled"
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram"
import { AiOutlineLinkedin } from "@react-icons/all-files/ai/AiOutlineLinkedin"
import { AiOutlineGithub } from "@react-icons/all-files/ai/AiOutlineGithub"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  gap: 2rem;
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const Main = styled.main`
  flex: 1 0 auto;
`

const Footer = styled.footer`
  flex-shrink: 0;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const Social = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5rem;

  a {
    color: #666;
    font-size: 24px;
    transition: color 0.2s;

    &:hover {
      color: #111;
    }
  }
`

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <Wrapper>
      <Header>
        <Title>
          <Link to="/">
            <StaticImage
              layout="fixed"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../images/p_m_logo.png"
              width={100}
              height={45}
              quality={100}
              alt="Profile picture"
            />
          </Link>
        </Title>
        <Navigation location={location} />
      </Header>
      <Main>{children}</Main>
      <Footer>
        © {new Date().getFullYear()} Phillip Michalowski
        <Social>
          <a href="https://www.instagram.com/onocy/" target="_blank" rel="noopener noreferrer">
            <AiOutlineInstagram />
          </a>
          <a href="https://www.linkedin.com/in/phillip-michalowski/" target="_blank" rel="noopener noreferrer">
            <AiOutlineLinkedin />
          </a>
          <a href="https://github.com/onocy" target="_blank" rel="noopener noreferrer">
            <AiOutlineGithub />
          </a>
        </Social>
      </Footer>
    </Wrapper>
  )
}

export default Layout
