import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`

const Logo = styled(Link)`
  margin-top: 2rem;
  margin-right: 2rem;
`

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  
  a {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    color: #111;
    transition: color 0.2s;
    
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: -4px;
      left: 0;
      background: #111;
      transition: width 0.2s;
    }
    
    &:hover:after,
    &.active:after {
      width: 100%;
    }
  }
`

const Navigation = ({ location }) => {
  return (
    <Nav>
      <Logo to="/">
        {/* Keep your existing StaticImage logo here */}
      </Logo>
      <NavLinks>
        <Link to="/" activeClassName="active">
          Home
        </Link>
        <Link to="/about" activeClassName="active">
          About
        </Link>
      </NavLinks>
    </Nav>
  )
}

export default Navigation
